import React from "react";

import "./BBAEdgeCard.css";

function BBAEdgeCard({ props }) {
  return (
    <div className="edge-cards">
      <img src={props.img} alt="Play" />
      <h5>{props.heading}</h5>
    </div>
  );
}

export default BBAEdgeCard;
