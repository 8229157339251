import React from "react";

import "./FeesPayment.css";

import Breadcrumb from "../../Components/breadcrumb/Breadcrumb";
import FooterCTASection from "../../Components/footerCTASection/footerCTASection";
import PrimaryBtn from "../../Components/PrimaryBtn/PrimaryBtn";

function FeesPayment() {
  return (
    <div className="fees-payment-page app-page-sections ">
      <div className=" fees-payment-main-section app-page-sub-sections">
        <div className="fees-payment-breadcrumbs-section ">
          <Breadcrumb heading="Fees Payment" />
        </div>
        <div className="fees-payment-mid-section ">
          <div className=" fees-payment-button-section">
            <h2 className=" ">Application Form Fees Payment</h2>
            <PrimaryBtn link="">Click here to pay</PrimaryBtn>
          </div>
          <div className="fees-payment-border"></div>

          <div className=" fees-payment-button-section">
            <h2 className=" ">Program Fees Payment</h2>
            <PrimaryBtn link="">Click here to pay</PrimaryBtn>
          </div>
        </div>
      </div>

      <FooterCTASection />
    </div>
  );
}

export default FeesPayment;
