export const companyDetails = {
  SocialMediaLinks: {
    companyInsta: "https://www.instagram.com/viven.eduversity/",
    companyFb: "https://m.facebook.com/viven.edu",
  },

  companyAddress2:
    "2nd Floor, LN College, beside fruit market, opp. General Kariappa Bridge, Rajendra Nagar, Borivali East, Mumbai, Maharashtra 400066",

  companyNumber: "+919920999904",
  companyNumber2: "+917045555255",
  companyEmail1: "info@viveneduversity.com",
  companyEmail2: "admissions@viveneduversity.com",
};
