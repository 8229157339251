import React from "react";
import "./ProgramBBA.css";

import PrimaryBtn from "../../Components/PrimaryBtn/PrimaryBtn";
import internalLinks from "../../internalLinks";
import ContactFormMain from "../../Components/contactFormMain/contactFormMain";

import BBADetailsCard from "../../Components/BBADetailsCard/BBADetailsCard";
import BBAEdgeCard from "../../Components/BBAEdgeCard/BBAEdgeCard";

import BBABannerBgImg from "../../Assets/ProgramPage/BBA/BBABannerBgImg.jpeg";
import BBADegreeMajorMainImg from "../../Assets/ProgramPage/BBA/BBADegreeMajorMainImg.jpeg";
import BBADegreeMinorMainImg from "../../Assets/ProgramPage/BBA/BBADegreeMinorMainImg.jpeg";

import BBADetailsImg1 from "../../Assets/ProgramPage/BBA/BBADetailsImg1.jpeg";
import BBADetailsImg2 from "../../Assets/ProgramPage/BBA/BBADetailsImg2.jpeg";
import BBADetailsImg3 from "../../Assets/ProgramPage/BBA/BBADetailsImg3.jpeg";
import BBADetailsImg4 from "../../Assets/ProgramPage/BBA/BBADetailsImg4.jpeg";
import BBADetailsImg5 from "../../Assets/ProgramPage/BBA/BBADetailsImg5.jpeg";
import BBADetailsImg6 from "../../Assets/ProgramPage/BBA/BBADetailsImg6.jpeg";

import BBAEdgeCardsPlayIconImg from "../../Assets/ProgramPage/BBA/BBAEdgeCardsPlayIconImg.jpeg";

import BBAAdvancementCardsImg1 from "../../Assets/ProgramPage/BBA/BBAAdvancementCardsImg1.jpeg";
import BBAAdvancementCardsImg2 from "../../Assets/ProgramPage/BBA/BBAAdvancementCardsImg2.jpeg";
import BBAAdvancementCardsImg3 from "../../Assets/ProgramPage/BBA/BBAAdvancementCardsImg3.jpeg";
import BBAAdvancementCardsImg4 from "../../Assets/ProgramPage/BBA/BBAAdvancementCardsImg4.jpeg";

import bgImage2 from "../../Assets/ProgramPage/bg img(1).png";

import BBAEligibilityImg from "../../Assets/ProgramPage/BBA/BBAEligibilityImg.jpeg";
import FooterCTASection from "../../Components/footerCTASection/footerCTASection";
import { Link } from "react-router-dom";
import ProgramButtons from "../../Components/ProgramButtons/ProgramButtons";

function ProgramBBA() {
  const BBADetailsCardData = [
    {
      img: BBADetailsImg1,
      heading: "Mode:",
      desc: "Offline Borivali (E & W ) / Andheri (E)",
    },
    {
      img: BBADetailsImg2,
      heading: "Duration:",
      desc: "4 Year's 8 Semesters",
    },
    {
      img: BBADetailsImg3,
      heading: "Credits:",
      desc: "",
    },
    {
      img: BBADetailsImg4,
      heading: "Exposure:",
      desc: "Domestic internship as well as International",
    },
    {
      img: BBADetailsImg5,
      heading: "Specialization:",
      desc: "Dual Degree + Certification",
    },
    {
      img: BBADetailsImg6,
      heading: "Activities:",
      desc: "More than 25+ events and activities",
    },
  ];

  const renderedBBADetailsCards = BBADetailsCardData.map((card, index) => (
    <BBADetailsCard props={card} key={index} />
  ));

  const BBAEdgeCardsData = [
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Industry Orionted syllabus",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Personality Development",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Program Structure",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Industry Ready Progtams",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "System Driven Management",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Career Skills",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Think Global Act Local",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Enterpreneurship and Leaders",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Student Driven Approach",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Spoken English",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Internship Based Education",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Placement",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Value Based Education",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Multiple Medium of Instruction",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Government Recognized Canter",
    },
    {
      img: BBAEdgeCardsPlayIconImg,
      heading: "Incubation",
    },
  ];

  const renderedBBAEdgeCards = BBAEdgeCardsData.map((data, index) => (
    <BBAEdgeCard props={data} key={index} />
  ));

  return (
    <div className="program-bba-container">
      {/* Banner Section */}
      <div
        className="banner-section"
        style={{ backgroundImage: `url(${BBABannerBgImg})` }}
      >
        <div className="banner-content">
          <h2>BACHELOR OF BUSINESS ADMINISTRATION</h2>
          {/* <span>
            Start your online degree program that is accredited in the UAE and
            UK.
          </span> */}
          <div className="btn-group">
            <PrimaryBtn link={internalLinks.applicationForm}>
              Get Started
            </PrimaryBtn>
            <Link to={internalLinks.scholarship}>
              <div className="scholarship-box">SCHOLARSHIPS AVAILABLE</div>
            </Link>
          </div>
        </div>
        <div className="contact-form-section">
          <h3>Get In Touch</h3>
          {/* <span>
            Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo
            molestie vel, ornare non id blandit netus.
          </span> */}
          <br />
          <div className="contact-form-wrapper">
            <ContactFormMain />
          </div>
        </div>
      </div>
      {/* Additional Sections */}
      <div className="additional-section ">
        <h3 className=" highlighted-heading-1">
          DEVELOP AND ADVANCE YOUR BUSINESS SKILLS
        </h3>
        <br />
        <p>
          Transform your capabilities and prepare to take your career to the
          next level with Viven Eduversity's BBA programme. Delve deeper into an
          innovative educational experience that goes beyond business
          fundamentals to develop practical skills. <br />
          <br /> Bachelor of Business Administration is a comprehensive
          four-year (8 semesters) undergraduate programme offering in-depth
          study of theoretical concepts and functional areas in Business
          Administration.
        </p>
      </div>
      {/* Degree Classification Section */}
      <div className="degree-classification-section">
        <h5>
          The following Major and Minor are available in the BBA program,
          students to choose one stream from Degree (Major) & one from
          Certification (Minor) below:
        </h5>
        <div className="degree-grid">
          <div className="degree-major">
            <h4 className=" highlighted-heading-1">Degree (Major)</h4>
            <ul>
              <li>BBA in Banking & Finance</li>
              <li>BBA in Retail</li>
              <li>BBA in Hospitality</li>
              <li>BBA in Wellness Management</li>
            </ul>
          </div>
          <img src={BBADegreeMajorMainImg} alt="Major" />
        </div>
        <div className="degree-grid">
          <div className="degree-minor">
            <h4 className=" highlighted-heading-1">Certification (Minor)</h4>
            <ul>
              <li>Sports Management</li>
              <li>Sports Analytics</li>
              <li>Interior Design</li>
              <li>Professional Sales</li>
              <li>Fashion Design</li>
              <li>Marketing</li>
              <li>Digital Marketing</li>
            </ul>
          </div>
          <img src={BBADegreeMinorMainImg} alt="Minor" />
        </div>
      </div>
      {/* BBA Details Cards section */}
      <div className="details-cards-section app-container-flex-center">
        {renderedBBADetailsCards}
      </div>
      {/* ------ */}
      <div className=" section-divider-2"></div>
      {/* The Different Edge Section */}
      <div className="different-edge-section app-container-flex-center">
        <div className="different-edge-text app-container-flex-center">
          <h3 className=" highlighted-heading-1">The Different EDGE</h3>
          <span>
            Academic programs of the university are unique with unmatched
            contents which includes following innovative practices
          </span>
        </div>
        <div className=" different-edge-cards-container app-container-flex-center ">
          {renderedBBAEdgeCards}
        </div>
      </div>
      {/* ---- */}

      <div className=" section-divider-2"></div>

      {/* Advancement Section */}
      <h3 className=" highlighted-heading-1">CAREER ADVANCEMENT</h3>
      <div className="bba-advanvcement-section app-container-flex-center">
        <div className="bba-advancement-heading app-container-flex-center">
          <h5 style={{ fontSize: "24px" }}>
            Experience Excellence in Education
          </h5>
        </div>
        <div className="bba-advancement-cards-section app-container-flex-center">
          <div className="bba-advancement-cards app-container-flex-center">
            <img src={BBAAdvancementCardsImg1} alt="Advancement" />
            <div className=" app-container-flex-center">
              <h5>Variety of Courses</h5>
              <p>
                Viven Eduversity offers a variety of courses to meet the needs
                of every student.
              </p>
            </div>
          </div>
          <div className="bba-advancement-cards app-container-flex-center">
            <img src={BBAAdvancementCardsImg2} alt="Advancement" />
            <div className=" app-container-flex-center">
              <h5>Campus Facilities</h5>
              <p>
                We provides a wide range of facilities to ensure that all
                students have the best possible educational experience.
              </p>
            </div>
          </div>
          <div className="bba-advancement-cards app-container-flex-center">
            <img src={BBAAdvancementCardsImg3} alt="Advancement" />
            <div className=" app-container-flex-center">
              <h5>Dual Learning</h5>
              <p>
                Every Students has two options to choose, one as Mojor subjects
                while another as Minor
              </p>
            </div>
          </div>
          <div className="bba-advancement-cards app-container-flex-center">
            <img src={BBAAdvancementCardsImg4} alt="Advancement" />
            <div className=" app-container-flex-center">
              <h5>Skilled Instructors</h5>
              <p>
                Get ready to learn from the best! Viven Eduversity provides
                skilled instructors for your educational needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* --------- */}
      <div className="bba-key-skills-sections app-container-flex-center">
        <h3 className=" highlighted-heading-1">
          Key Skills Acquired after the end of the BBA program:
        </h3>
        <ul className=" app-container-flex-center">
          <li>Financial Accounting Skills</li>
          <li>Business Statistics Skills</li>
          <li>Retail Banking Skills</li>
          <li>Mutual Fund Distribution Skills</li>
          <li>Securities Market Operations Skills</li>
          <li>Risk Management Skills</li>
        </ul>
      </div>
      <div className=" section-divider-2"></div>
      <div className="program-page-eligibility">
        <div className="program-page-eligibility-heading">
          <h2 className=" highlighted-heading-1">Eligibility</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="83"
            height="149"
            viewBox="0 0 83 149"
            fill="none"
          >
            <path
              d="M4.37769 9.91455C1.66345 9.4062 -0.124763 6.79378 0.383591 4.07955C0.891937 1.36532 3.50436 -0.422897 6.21858 0.085453L4.37769 9.91455ZM22.5285 148.5C19.7671 148.516 17.5158 146.29 17.5 143.529L17.2428 98.5293C17.2271 95.7679 19.4528 93.5166 22.2142 93.5008C24.9756 93.485 27.2269 95.7108 27.2427 98.4722L27.4713 138.472L67.4706 138.243C70.232 138.227 72.4833 140.453 72.4991 143.214C72.5149 145.976 70.2891 148.227 67.5278 148.243L22.5285 148.5ZM6.21858 0.085453C26.1005 3.80915 43.0497 8.93197 55.8311 16.1179C68.6656 23.3336 77.7423 32.9012 80.8557 45.5781C83.9426 58.1476 80.8825 72.65 71.9696 89.1778C63.0439 105.729 47.9658 124.853 26.0556 147.015L18.9442 139.985C40.534 118.147 54.8809 99.7983 63.1678 84.4314C71.4675 69.0407 73.4074 57.1784 71.1442 47.9631C68.9074 38.8552 62.2839 31.2178 50.9304 24.8347C39.5237 18.4217 23.7971 13.5516 4.37769 9.91455L6.21858 0.085453Z"
              fill="#11458F"
            />
          </svg>
        </div>
        <div
          className="program-page-eligibility-main"
          style={{
            backgroundImage: `url("${bgImage2}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="program-page-eligibility-main-content">
            <div className="program-page-eligibility-main-content-left">
              <div className="program-page-eligibility-main-content-left-heading">
                <h3>Principal objective</h3>
              </div>
              <div className="program-page-eligibility-main-content-left-point">
                <p>Learn more, During graduation (Dual Program)</p>
              </div>
              <div className="program-page-eligibility-main-content-left-heading">
                <h3>Entrance Exam</h3>
              </div>
              <div className="program-page-eligibility-main-content-left-point">
                <p>Own Entrance Exam</p>
              </div>
              <div className="program-page-eligibility-main-content-left-heading">
                <h3>Admission Process</h3>
              </div>
              <div className="program-page-eligibility-main-content-left-point">
                <p>Personal Interview</p>
              </div>
              <div className="program-page-eligibility-main-content-left-heading">
                <h3>Percentage</h3>
              </div>
              <div className="program-page-eligibility-main-content-left-point">
                <p>45% and above in HSC or equivalent to 12th </p>
              </div>
              <div className="program-page-eligibility-main-content-left-heading">
                <h3>Batch Size </h3>
              </div>
              <div className="program-page-eligibility-main-content-left-point">
                <p> 60</p>
              </div>
            </div>

            <div className="program-page-eligibility-main-content-right">
              <img
                className="program-page-eligibility-main-content-right-img"
                src={BBAEligibilityImg}
                alt="eligibilityImage"
              />
            </div>
          </div>
        </div>

        {/* <div className='program-page-learning-philosophy-apply-button'>
            <button className='apply-button'>Apply Now</button>
            <button className='download-button'>
              DOWNLOAD BROCHURE
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='19'
                viewBox='0 0 18 19'
                fill='none'>
                <path
                  d='M8.93378 9.12785V15.4556M8.93378 15.4556L11.1671 13.2223M8.93378 15.4556L6.70045 13.2223M5.956 5.80465C6.51307 5.88432 7.02912 6.14299 7.42628 6.54165M13.0282 10.989C14.159 10.989 14.8893 10.0726 14.8893 8.94174C14.8893 8.49405 14.7425 8.05871 14.4714 7.7024C14.2004 7.34609 13.82 7.08846 13.3885 6.96897C13.3222 6.13404 12.9761 5.3459 12.4064 4.73198C11.8366 4.11806 11.0765 3.71423 10.2488 3.58578C9.42119 3.45734 8.57441 3.61179 7.8454 4.02416C7.11639 4.43653 6.54776 5.08272 6.23145 5.85825C5.56549 5.67365 4.85347 5.76116 4.25203 6.10153C3.65058 6.4419 3.20899 7.00725 3.02438 7.67321C2.83978 8.33917 2.92728 9.05119 3.26765 9.65263C3.60802 10.2541 4.17338 10.6957 4.83934 10.8803'
                  stroke='#52525B'
                  stroke-width='1.48889'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </button>
          </div> */}
      </div>
      <FooterCTASection />
    </div>
  );
}

export default ProgramBBA;
