import React from "react";

import "./DownloadBrochureComp.css";

import { VscSend } from "react-icons/vsc";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { getFirestore, collection, addDoc } from "firebase/firestore";
import app from "../../firebase";

import programList from "../ProgramDetails/ProgramDetails";

import BrochureMBA2024 from "../../Assets/Brochures/VivenMbaBrochure2024.pdf";
import BrochureBBA2024 from "../../Assets/Brochures/VivenBBABrochure2024.pdf";
import BrochurePGDM2024 from "../../Assets/Brochures/VivenPGDMBrochure2024.pdf";
import BrochureCommon2024 from "../../Assets/Brochures/VivenBrochureCommon2024.pdf";

function DownloadBrochureComp({ buttonName, buttonStyle, brochureName }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fullName, setFullName] = React.useState();
  const [contact, setContact] = React.useState();
  const [email, setEmail] = React.useState();

  const [certification, setCertification] = React.useState(programList[0]);

  const handleChange = (event) => {
    setCertification(event.target.value);
  };

  const db = getFirestore(app);

  const handleDownload = () => {
    if (brochureName) {
      if (brochureName === "MBA") {
        window.open(BrochureMBA2024, "_blank");
      } else if (brochureName === "BBA") {
        window.open(BrochureBBA2024, "_blank");
      } else if (brochureName === "PGDM") {
        window.open(BrochurePGDM2024, "_blank");
      }
    } else {
      window.open(BrochureCommon2024, "_blank");
    }
  };

  const handleDemoSubmit = async (e) => {
    e.preventDefault();

    // console.log({
    //   FullName: fullName,
    //   Contact: contact,
    //   Email: email,
    //   Certification: certification,
    // });
    const date = new Date().toLocaleString();
    const docRef = await addDoc(collection(db, "demo"), {
      FullName: fullName,
      Contact: contact,
      Email: email,
      Certification: certification,
      date: date,
    });

    // console.log("Document written with ID: ", docRef.id);
    handleDownload();
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };

  const ModalData = (
    <div className="ProgramPGDMDigitalMarketing-ModalForm">
      <h2>{buttonName}</h2>
      <form
        className="ProgramPGDMDigitalMarketing-ModalForm-form"
        onSubmit={handleDemoSubmit}
      >
        <input
          placeholder="Full Name"
          type="text"
          required
          onChange={(e) => setFullName(e.target.value)}
        />
        <input
          placeholder="Contact Phone"
          type="number"
          required
          onChange={(e) => setContact(e.target.value)}
        />
        <input
          placeholder="E-mail"
          type="email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <div style={{ width: "83%" }}>
          <FormControl
            variant="standard"
            sx={{ width: "100%", outline: "none" }}
          >
            <InputLabel
              id="demo-simple-select-standard-label"
              style={{ paddingLeft: "1rem", fontSize: "14px" }}
            >
              Which degree do you prefer ?
            </InputLabel>
            <Select
              style={{ fontSize: "14px" }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={certification}
              onChange={handleChange}
              label="Certification"
            >
              {programList.map((pname, index) => (
                <MenuItem
                  key={index}
                  style={{ fontSize: "14px" }}
                  value={`${pname}`}
                >
                  {pname}
                </MenuItem>
              ))}
              {/* <MenuItem
                style={{ fontSize: "14px" }}
                value={"PGPEFB (PGP in Entrepreneurship & Family Business)"}
              >
                PGPEFB (PGP in Entrepreneurship & Family Business)
              </MenuItem>
              <MenuItem
                style={{ fontSize: "14px" }}
                value={"PGPM (Post Graduate Programme in Management)"}
              >
                PGPM (Post Graduate Programme in Management)
              </MenuItem>
              <MenuItem
                style={{ fontSize: "14px" }}
                value={"PGDM (One Year PGDM in Digital Marketing)"}
              >
                PGDM (One Year PGDM in Digital Marketing)
              </MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div
          className=""
          style={{ display: "flex", flexDirection: "row", gap: "10px" }}
        >
          <input type="checkbox" required />
          <p style={{ fontSize: "12px" }}>
            I agree to Viven Eduversity's Terms and Conditions and their Privacy
            Policy
          </p>
        </div>
        <button
          type="submit"
          className="ProgramPGDMDigitalMarketing-ModalForm-button"
          style={{}}
        >
          <h3>Send Now</h3>
          <VscSend style={{ fontSize: "25px" }} />
        </button>
      </form>
    </div>
  );

  return (
    <div className=" download-brochure-comp">
      <div>
        <button
          onClick={handleOpen}
          className={`${
            buttonStyle === "list"
              ? "brochure-list-style-button"
              : "brochure-button-style-button"
          }`}
        >
          {buttonName}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M9.10001 8.95281V15.2806M9.10001 15.2806L11.3333 13.0472M9.10001 15.2806L6.86668 13.0472M6.12223 5.62961C6.6793 5.70927 7.19535 5.96794 7.59251 6.36661M13.1945 10.8139C14.3253 10.8139 15.0556 9.89751 15.0556 8.76669C15.0555 8.319 14.9087 7.88366 14.6377 7.52735C14.3666 7.17104 13.9862 6.91341 13.5548 6.79392C13.4884 5.95899 13.1424 5.17086 12.5726 4.55693C12.0029 3.94301 11.2427 3.53918 10.4151 3.41073C9.58742 3.28229 8.74064 3.43674 8.01163 3.84911C7.28261 4.26148 6.71399 4.90767 6.39768 5.68321C5.73172 5.4986 5.0197 5.58611 4.41826 5.92648C3.81681 6.26685 3.37522 6.8322 3.19061 7.49816C3.00601 8.16412 3.09351 8.87614 3.43388 9.47758C3.77425 10.079 4.33961 10.5206 5.00557 10.7052"
              stroke="#52525B"
              stroke-width="1.48889"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{ModalData}</Box>
      </Modal>
    </div>
  );
}

export default DownloadBrochureComp;
