const internalLinks = {
  home: "/",
  aboutUs: "/about-us",
  programs: {
    program1: "/programs/MBA-INVM",
    program2: "/programs/MBA-BIBDM",
    program3: "/programs/PGDM",
    program4: "programs/BBA",
  },
  addmissions: {},
  resources: {},
  applicationForm: "/application-form",
  corporatePartners: "/corporate-partners",
  contactUs: "/contact-us",
  workWithUs: "/work-with-us",
  generalTermsAndCondtion: "/generalTerms",
  disclaimer: "/disclaimer",
  privacyPolicy: "/privacy-policy",
  refundPolicy: "/refund-policy",

  feesPayment: "/fees-payment",

  scholarship: "/scholarship",
  singleBlogPage: "/blog",
  blogsPage: "/blogs",
};

export default internalLinks;
