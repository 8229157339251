import React from "react";

import "./BlogPageMain.css";

import BlogsContent from "../../Components/BlogsContent/BlogsContent";
import FooterCTASection from "../../Components/footerCTASection/footerCTASection";
import { Link } from "react-router-dom";
import internalLinks from "../../internalLinks";

function BlogPageMain() {
  const firstBlog = BlogsContent[0];

  return (
    <div className=" app-page-sections blogs-page">
      <div className=" app-page-sub-sections blogs-page-section">
        <Link to={`${internalLinks.singleBlogPage}/0`}>
          <div
            className=" app-container-flex-center blogs-page-main-blog"
            style={{ backgroundImage: `url(${firstBlog.mainImg})` }}
          >
            <h4>Click To View Full Blog</h4>
            <div>
              <h3>{firstBlog.blogTitle}</h3>
              <span>
                <b>By</b>&nbsp; {firstBlog.authorName}
              </span>
            </div>
          </div>
        </Link>
        <div className=" app-container-flex-center blogs-page-blogs-showcase-section">
          {BlogsContent.map((blog, index) => (
            <Link to={`${internalLinks.singleBlogPage}/${blog.id}`}>
              <div
                key={index}
                className=" app-container-flex-center blogs-page-blogs-card"
              >
                <img src={blog.mainImg} alt="Blog" />
                <div>
                  <h3>{blog.blogTitle}</h3>
                  <span>
                    <b>By</b>&nbsp; {blog.authorName}
                  </span>
                </div>
                <p>{blog.blogParas[0].para}</p>
                <span>{blog.blogDate}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <FooterCTASection />
    </div>
  );
}

export default BlogPageMain;
