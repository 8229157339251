import React from "react";
import { useParams } from "react-router-dom";

import "./SingleBlogPage.css";

import BlogsContent from "../../Components/BlogsContent/BlogsContent";
import FooterCTASection from "../../Components/footerCTASection/footerCTASection";

function SingleBlogPage() {
  const { id } = useParams();

  const currentBlog = BlogsContent.find((blog) => blog.id === parseInt(id));

  if (!currentBlog) {
    return (
      <div className=" blog-page-error">
        <h2>Blog Not Found!</h2>
      </div>
    );
  }
  return (
    <div className=" app-page-sections single-blog-page">
      <div className=" app-page-sub-sections single-blog-page-sub-section">
        <div
          className=" app-container-flex-center single-blog-page-banner-section"
          style={{ backgroundImage: `url( ${currentBlog.mainImg})` }}
        >
          <div className=" app-container-flex-center single-blog-page-banner-text-section">
            <h1>{currentBlog.blogTitle}</h1>
            <div className=" app-container-flex-center">
              <span>
                {" "}
                <b>By</b>&nbsp; {currentBlog.authorName}
              </span>
              <span>{currentBlog.blogDate}</span>
            </div>
          </div>
        </div>
        <div className=" app-container-flex-center single-blog-page-content-container ">
          <h2>{currentBlog.blogTitle}</h2>
          {currentBlog.blogParas.map((textSection, index) => (
            <div
              key={index}
              className=" app-container-flex-center single-blog-page-para-container"
            >
              <h4 key={index}>{textSection.paraHeading}</h4>
              {textSection.para.map((para, index) => (
                <p key={index}>{para}</p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <FooterCTASection />
    </div>
  );
}

export default SingleBlogPage;
