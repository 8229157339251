import React from "react";
import { Link } from "react-router-dom";

import "./PrimaryBtn.css";

function PrimaryBtn(props) {
  return (
    <Link to={props.link}>
      <button className=" primary-button capitalize bg-primaryColor text-primaryWhite font-semibold px-7 py-2 rounded-full ">
        {props.children}
      </button>
    </Link>
  );
}

export default PrimaryBtn;
