import React from "react";

import "./BBADetailsCard.css";

function BBADetailsCard({ props }) {
  return (
    <div className=" bba-details-card app-container-flex-center">
      <img src={props.img} alt="BBA Details" />
      <div>
        <h5>{props.heading}</h5>
        <span>{props.desc}</span>
      </div>
    </div>
  );
}

export default BBADetailsCard;
