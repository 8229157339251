import React, { useState } from "react";
import { useEffect } from "react";

import "./Scholarship.css";

import internalLinks from "../../internalLinks";

import PrimaryBtn from "../../Components/PrimaryBtn/PrimaryBtn";
import FooterCTASection from "../../Components/footerCTASection/footerCTASection";

import BannerStarImg from "../../Assets/Scholarship/BannerStarImg.png";
import SchBannerImg1 from "../../Assets/Scholarship/SchBannerImg1.png";
import SchBannerImg2 from "../../Assets/Scholarship/SchBannerImg2.png";
import SchSupportersLogosImg from "../../Assets/Scholarship/SchSupportersLogosImg.png";
import SchChoiceIconImg from "../../Assets/Scholarship/SchChoiceIconImg.png";
import SchClaimIconImg from "../../Assets/Scholarship/SchClaimIconImg.png";
import SchSupportIconImg from "../../Assets/Scholarship/SchSupportIconImg.png";

import SchTopSchImg1 from "../../Assets/Scholarship/SchTopSchImg1.jpeg";
import SchTopSchImg2 from "../../Assets/Scholarship/SchTopSchImg2.jpeg";
import SchTopSchImg3 from "../../Assets/Scholarship/SchTopSchImg3.jpeg";

import SchWelcomeMainImg from "../../Assets/Scholarship/SchWelcomeMainImg.png";
import SchCardHeartImg from "../../Assets/Scholarship/SchCardHeartImg.png";
import SchCardManImg from "../../Assets/Scholarship/SchCardManImg.png";

function Scholarship() {
  const [startAnimation, setStarAnimation] = useState(false);

  useEffect(() => {
    setStarAnimation(true);

    setTimeout(() => {
      setStarAnimation(false);
    }, 500);
  }, []);

  return (
    <div className=" scholarship-section app-page-sections">
      {/* Banner Section */}
      <div className="scholarship-banner app-container-flex-center">
        <div className="scholarship-banner-main app-container-flex-center">
          <img
            className={` ${startAnimation ? " rotate-180 " : " -rotate-180"}`}
            src={BannerStarImg}
            alt="Stars"
          />
          <h2 className=" ">Get a Chance to Make Your Dream Happen</h2>
          <img
            className={` ${startAnimation ? " rotate-180 " : " -rotate-180"}`}
            src={BannerStarImg}
            alt="Stars"
          />
        </div>
        <div className=" scholarhip-banner-mid app-container-flex-center ">
          <img className="" src={SchBannerImg1} alt="Scholarship" />
          <div className="scholarhip-banner-mid-text app-container-flex-center">
            <PrimaryBtn link={internalLinks.applicationForm}>
              Get Started
            </PrimaryBtn>
            <span>
              We support those who want to have the opportunity to study at
              their dream college for a better future, and the development of
              our young people for a better world
            </span>
          </div>
          <img className="" src={SchBannerImg2} alt="Scholarship" />
        </div>
      </div>
      {/* ---- */}

      {/* Supporters Section */}

      {/* <div className=" scholarship-supporters-section app-container-flex-center  ">
        <div className=" ">
          <h4 className=" ">Supported by 100+ companies and platform</h4>
        </div>
        <div className=" ">
          <img src={SchSupportersLogosImg} alt="Supporters" />
        </div>
      </div> */}

      {/* -------- */}

      {/* Easy Scholarship Section */}

      {/* <div className=" scholarship-easy-section app-container-flex-center">
        <div className=" scholarship-easy-heading-section app-container-flex-center">
          <h2 className=" ">Easy To Find Scholarship</h2>
          <span>
            Limited costs are not a reason to achieve higher education. If you
            know how to get a free college scholarship, you can easily achieve
            your dreams and goals
          </span>
        </div>
        <div className=" scholarship-easy-cards-section app-container-flex-center">
          <div className="scholarship-easy-cards app-container-flex-center">
            <img className=" " src={SchChoiceIconImg} alt="Choice" />
            <div className=" ">
              <h4 className=" ">Lots Of Choices</h4>
              <span>
                There are many categories and types of scholarships to choose
                from
              </span>
            </div>
          </div>
          <div className="scholarship-easy-cards app-container-flex-center">
            <img className=" " src={SchClaimIconImg} alt="Claim" />
            <div className=" ">
              <h4 className=" ">Easy Claim Process</h4>
              <span>
                The process for you to get a scholarship is clearer, making it
                easier
              </span>
            </div>
          </div>
          <div className="scholarship-easy-cards app-container-flex-center">
            <img className=" " src={SchSupportIconImg} alt="Support" />
            <div className=" ">
              <h4 className=" ">Our Full Support</h4>
              <span>
                Get our full support, to help you get the scholarship of your
                dreams
              </span>
            </div>
          </div>
        </div>
      </div> */}

      {/* ------- */}

      {/* Top Choice Section */}

      {/* <div className="scholarship-top-choice-section app-container-flex-center">
        <div className="scholarship-top-choice-heading-section">
          <h3 className=" ">Top Choice Scholarship</h3>
          <span>
            Here we present popular scholarship offers that are in great demand,
            if interested, register yourself immediately
          </span>
        </div>
        <div className="scholarship-top-choice-cards-section app-container-flex-center w-[100%] flex flex-wrap gap-10 justify-center items-center ">
          <div className="scholarship-top-choice-cards app-container-flex-center">
            <img className=" " src={SchTopSchImg1} alt="Scholarship" />
            <h4 className=" ">S2 College Scholarships</h4>
            <span>
              Scholarships to continue S2 in many majors and campuses in various
              countries completely free of charge
            </span>
          </div>
          <div className=" scholarship-top-choice-cards app-container-flex-center">
            <img className=" " src={SchTopSchImg2} alt="Scholarship" />
            <h4 className=" ">S2 College Scholarships</h4>
            <span>
              Scholarships to continue S2 in many majors and campuses in various
              countries completely free of charge
            </span>
          </div>
          <div className=" scholarship-top-choice-cards app-container-flex-center">
            <img className=" " src={SchTopSchImg3} alt="Scholarship" />
            <h4 className=" ">S2 College Scholarships</h4>
            <span>
              Scholarships to continue S2 in many majors and campuses in various
              countries completely free of charge
            </span>
          </div>
        </div>
      </div> */}

      {/* ------- */}

      {/* Welcome Section */}

      <div className=" scholarship-welcome-section app-container-flex-center">
        <img className={` `} src={BannerStarImg} alt="Stars" />

        {/* <div className="scholarship-welcome-image-section app-container-flex-center ">
          <img className=" " src={SchWelcomeMainImg} alt="Welcome" />
        </div> */}
        <div className=" scholarship-welcome-text-section app-container-flex-center">
          <h3 className=" text-4xl font-semibold">MBA Program</h3>
          <span>
            Every Student deserves a Scholarship. We provide Merit based
            scholarship as well as sports quota to all MBA applicants.
          </span>
          <span>
            {" "}
            <b>Sports Quota - </b>
          </span>
          <span>50% on second years fees for Sports athlete</span>
          <br />
          <span>
            {" "}
            <b>Merit Based – </b>
          </span>
          <span>
            50% on second years fees, above 70%ile in CET/CAT & 30% on second
            years fees, between 50-70%ile in CET/CAT
          </span>
          <div>
            <PrimaryBtn link={internalLinks.applicationForm}>
              Apply Now
            </PrimaryBtn>
          </div>
        </div>
        <img className={` `} src={BannerStarImg} alt="Stars" />
      </div>

      {/* -------- */}

      <div className=" scholarship-welcome-section app-container-flex-center">
        <img className={` `} src={BannerStarImg} alt="Stars" />

        {/* <div className="scholarship-welcome-image-section app-container-flex-center ">
          <img className=" " src={SchWelcomeMainImg} alt="Welcome" />
        </div> */}
        <div className=" scholarship-welcome-text-section app-container-flex-center">
          <h3 className=" text-4xl font-semibold">BBA Program</h3>
          <span>Every Student deserves a Scholarship</span>
          <span>
            {" "}
            All BBA + MBA (Integrated program) enrolled students will get
            Rs.50,000/- scholarship every year.
          </span>
          <div>
            <PrimaryBtn link={internalLinks.applicationForm}>
              Apply Now
            </PrimaryBtn>
          </div>
        </div>
        <img className={` `} src={BannerStarImg} alt="Stars" />
      </div>

      {/* Scholarship Card */}

      <div className=" scholarship-main-card app-container-flex-center">
        <div className="scholarship-main-card-left app-container-flex-center">
          <h4 className=" ">Start Looking For Your Scholarship </h4>
          <span>
            We have a wide selection of scholarships for you to apply for, so
            join now and find your best
          </span>
          <PrimaryBtn link={internalLinks.applicationForm}>
            Get Started
          </PrimaryBtn>
        </div>
        <div className=" ">
          <img className=" " src={SchCardHeartImg} alt="Heart" />
        </div>
        <div className="scholarship-main-card-right">
          <img className=" " src={SchCardManImg} alt="Man" />
          <h4 className=" ">Scholarship</h4>
          <span className=" ">For Everyone</span>
        </div>
      </div>

      {/* --------- */}

      {/* Footer */}
      <FooterCTASection />
    </div>
  );
}

export default Scholarship;
