import React from "react";

import { useNavigate } from "react-router-dom";

import { VscSend } from "react-icons/vsc";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { getFirestore, collection, addDoc } from "firebase/firestore";
import app from "../../firebase";
import DownloadBrochureComp from "../DownloadBrochureComp/DownloadBrochureComp";

export default function ProgramButtons({ brochureName }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [fullName, setFullName] = React.useState();
  const [contact, setContact] = React.useState();
  const [email, setEmail] = React.useState();

  const [certification, setCertification] = React.useState(
    "PGPEFB (PGP in Entrepreneurship & Family Business)"
  );

  const handleChange = (event) => {
    setCertification(event.target.value);
  };

  const db = getFirestore(app);

  const handleDemoSubmit = async (e) => {
    e.preventDefault();

    // console.log({
    //   FullName: fullName,
    //   Contact: contact,
    //   Email: email,
    //   Certification: certification,
    // });
    const date = new Date().toLocaleString();
    const docRef = await addDoc(collection(db, "demo"), {
      FullName: fullName,
      Contact: contact,
      Email: email,
      Certification: certification,
      date: date,
    });

    console.log("Document written with ID: ", docRef.id);
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };

  const ModalData = (
    <div className="ProgramPGDMDigitalMarketing-ModalForm">
      <h2>Enquire Now</h2>
      <form
        className="ProgramPGDMDigitalMarketing-ModalForm-form"
        onSubmit={handleDemoSubmit}
      >
        <input
          placeholder="Full Name"
          type="text"
          required
          onChange={(e) => setFullName(e.target.value)}
        />
        <input
          placeholder="Contact Phone"
          type="number"
          required
          onChange={(e) => setContact(e.target.value)}
        />
        <input
          placeholder="E-mail"
          type="email"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <div style={{ width: "83%" }}>
          <FormControl
            variant="standard"
            sx={{ width: "100%", outline: "none" }}
          >
            <InputLabel
              id="demo-simple-select-standard-label"
              style={{ paddingLeft: "1rem", fontSize: "14px" }}
            >
              which certification do you prefer ?
            </InputLabel>
            <Select
              style={{ fontSize: "14px" }}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={certification}
              onChange={handleChange}
              label="Certification"
            >
              <MenuItem
                style={{ fontSize: "14px" }}
                value={"PGPEFB (PGP in Entrepreneurship & Family Business)"}
              >
                PGPEFB (PGP in Entrepreneurship & Family Business)
              </MenuItem>
              <MenuItem
                style={{ fontSize: "14px" }}
                value={"PGPM (Post Graduate Programme in Management)"}
              >
                PGPM (Post Graduate Programme in Management)
              </MenuItem>
              <MenuItem
                style={{ fontSize: "14px" }}
                value={"PGDM (One Year PGDM in Digital Marketing)"}
              >
                PGDM (One Year PGDM in Digital Marketing)
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div
          className=""
          style={{ display: "flex", flexDirection: "row", gap: "10px" }}
        >
          <input type="checkbox" required />
          <p style={{ fontSize: "12px" }}>
            I agree to Eduversity Terms and Conditions and their Privacy Policy
          </p>
        </div>
        <button
          type="submit"
          className="ProgramPGDMDigitalMarketing-ModalForm-button"
          style={{}}
        >
          <h3>Send Now</h3>
          <VscSend style={{ fontSize: "25px" }} />
        </button>
      </form>
    </div>
  );

  const history = useNavigate();
  return (
    <div className="program-page-learning-philosophy-apply-button">
      <button
        onClick={() => history("/application-form")}
        className="apply-button"
      >
        Apply Now
      </button>
      <DownloadBrochureComp
        buttonName="Download Brochure"
        buttonStyle="button"
        brochureName={brochureName}
      />
      {/* <button className="download-button" onClick={handleOpen}>
        DOWNLOAD BROCHURE
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
        >
          <path
            d="M8.93378 9.12785V15.4556M8.93378 15.4556L11.1671 13.2223M8.93378 15.4556L6.70045 13.2223M5.956 5.80465C6.51307 5.88432 7.02912 6.14299 7.42628 6.54165M13.0282 10.989C14.159 10.989 14.8893 10.0726 14.8893 8.94174C14.8893 8.49405 14.7425 8.05871 14.4714 7.7024C14.2004 7.34609 13.82 7.08846 13.3885 6.96897C13.3222 6.13404 12.9761 5.3459 12.4064 4.73198C11.8366 4.11806 11.0765 3.71423 10.2488 3.58578C9.42119 3.45734 8.57441 3.61179 7.8454 4.02416C7.11639 4.43653 6.54776 5.08272 6.23145 5.85825C5.56549 5.67365 4.85347 5.76116 4.25203 6.10153C3.65058 6.4419 3.20899 7.00725 3.02438 7.67321C2.83978 8.33917 2.92728 9.05119 3.26765 9.65263C3.60802 10.2541 4.17338 10.6957 4.83934 10.8803"
            stroke="#52525B"
            stroke-width="1.48889"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{ModalData}</Box>
      </Modal>
    </div>
  );
}
